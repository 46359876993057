import React, { useEffect } from 'react';
import { EntityLayout } from '@lego/plugin-baseplate-catalog';
import { Grid, Typography } from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';
import { MarkdownContent } from '@backstage/core-components';
import {
  featureFlagsApiRef,
  useAnalytics,
  useApi,
} from '@backstage/core-plugin-api';
import { isTechDocsAvailable } from '@backstage/plugin-techdocs';
import {
  CommunityMembers,
  CommunityProvider,
  EditCommunityPage,
  JoinButton,
  LeaveDialog,
  LatestActivity,
  useCommunity,
} from '@lego/plugin-baseplate-communities';
import {
  Card,
  LinkCard,
  Spinner,
} from '@lego/plugin-baseplate-core-components';
import { EntityEventsCard } from '@lego/plugin-baseplate-learning-and-development';
import { EntityTechdocs } from '../../documentation/EntityTechdocs';

const CommunityPageContent = () => {
  const {
    entity,
    gitHubReadMeContent,
    gitHubReadMeError,
    gitHubReadMeLoading,
    isUserMaintainer,
  } = useCommunity();
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const isFeatureFlagOn = featureFlagsApi.isActive('communities');

  const renderAboutCardContent = ({
    currentEntity,
    readMeContentToDisplay,
  }: {
    currentEntity: Entity;
    readMeContentToDisplay?: string | null;
  }) => {
    if (gitHubReadMeLoading) {
      return <Spinner />;
    }

    if (gitHubReadMeError) {
      return (
        <Typography variant="body1" color="error">
          Error loading this community's "About" content. Please try again
          later.
        </Typography>
      );
    }

    if (
      currentEntity.spec &&
      currentEntity.spec.aboutSource === 'github' &&
      readMeContentToDisplay
    ) {
      return (
        <MarkdownContent
          content={readMeContentToDisplay}
          dialect="gfm"
          className="markdown"
        />
      );
    } else if (currentEntity.spec && currentEntity.spec.about) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: atob(currentEntity.spec?.about as string),
          }}
        />
      );
    }
    return <div>No "About" content provided</div>;
  };

  return (
    <EntityLayout
      breadcrumbLinks={[
        {
          label: 'Communities',
          href: `/communities/overview?filters%5Bkind%5D=community`,
        },
        {
          label: entity?.metadata.title ?? entity?.metadata.name ?? 'Community',
        },
      ]}
      actions={[<JoinButton />]}
    >
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Card title="About">
              {entity &&
                renderAboutCardContent({
                  currentEntity: entity,
                  readMeContentToDisplay: gitHubReadMeContent,
                })}
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
          >
            <Card title="Channels">
              <Grid
                style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
              >
                {entity && entity.spec && entity.spec.githubRepoLink && (
                  <LinkCard
                    title="GitHub source"
                    url={entity.spec?.githubRepoLink}
                  />
                )}
                {entity && entity.spec && entity.spec.teamsChannelLink && (
                  <LinkCard
                    title="Teams source"
                    url={entity.spec?.teamsChannelLink}
                  />
                )}
                {entity &&
                  entity.metadata.links &&
                  entity.metadata.links.map(link => (
                    <LinkCard
                      title={link.title}
                      url={link.url}
                      key={link.url}
                    />
                  ))}
              </Grid>
            </Card>
            <LatestActivity />
            <EntityEventsCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      {entity && (
        <>
          {isTechDocsAvailable(entity) && (
            <EntityLayout.Route path="/docs" title="Docs">
              <EntityTechdocs />
            </EntityLayout.Route>
          )}
          <EntityLayout.Route path="/members" title="Members">
            <CommunityMembers entity={entity} />
          </EntityLayout.Route>
          {isUserMaintainer && isFeatureFlagOn && (
            <EntityLayout.Route path="/edit" title="Edit (maintainers only)">
              <EditCommunityPage />
            </EntityLayout.Route>
          )}
        </>
      )}
    </EntityLayout>
  );
};

const CommunityPage = () => {
  const analytics = useAnalytics();
  useEffect(() => {
    analytics.captureEvent('community_engagement', 'community_page_visit');
  }, [analytics]);

  return (
    <CommunityProvider>
      <CommunityPageContent />;
      <LeaveDialog />
    </CommunityProvider>
  );
};

export default CommunityPage;
